
.recognition-content {
    height: 100%; 
    padding: 2em; 
    display: flex; 
    flex-direction: column; 
    text-align: center; 
}

.recognition-body {
    display: flex; 
    flex-direction: row; 
    align-items: center;
    padding-top: 2em;
    padding-left: 4em; 
    padding-right: 4em;
    font-size: 1.2rem;
}

.recognition-image {
    margin-left: auto; 
    margin-right: auto; 
    height: 15em; 
    width: 15em; 
    background-color: white;
    border-radius: 50%;
    padding: 0.5em;
}

.recognition-donate-button {
    background-color: white; 
    color: black; 
    border: 1px solid white; 
    font-weight: bold; 
    font-size: 1.2rem; 
    width: 5em; 
    padding: 0.5em;    

    transition: ease-out 0.4s; 
    box-shadow: inset 0 0 0 0 black; 

    &:hover {
        // background-color: white;
        // color: black; 
        // border-color: black;
        box-shadow: inset 10em 0 0 0 black; 
        color: white;
    }
    margin-top: 5em;
    margin-left: auto; 
    margin-right: auto; 
    width: 10em;
}




.r-carousel {
  margin: 0 auto;
  padding: 20px 0;
  max-width: 100%;
  overflow: hidden;
  height: 400px;
  display: flex;
  margin-top: auto;
  > * {
    flex: 0 0 100%;
  }
}

.r-card {
  width: 100%;
  min-width: 400px;
  max-width: 400px;
  color: black;

  box-shadow: rgba(255, 255, 255, 0.45) 5px 5px 20px 0;
  padding-left: 0; 
  padding-right: 0;
  font-size: xx-large;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  background-color: white; 

  .rec-card-header { 
    padding-top: 20px;
    border-radius: 24px 24px 0 0;
    font-weight: bolder; 
    border-bottom: 2px solid black;
    text-align: center; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    // background-color: rgb(0, 191, 255); 
    font-family: monospace; 
  }
  .rec-card-img {

    padding-top: 0.5em;
    padding-bottom: 0.5em;
    display: flex; 
    justify-content: center;
    img {
      width: 200px;
      height: 200px;
      object-fit: cover; 
    } 
  }
}

.r-group {
  display: flex;
  gap: 20px;
  /* Add padding to the right to create a gap between the last and first card. */
  padding-right: 20px;


}

@keyframes scrolling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.r-animate {
  .r-group {
    will-change: transform; 
    animation: scrolling 60s linear infinite; 
  }

  &:hover {
    .r-group {
      animation-play-state: paused;
    }
  }
}


@media only screen and (max-width: 600px) {
  .recognition-body {
    padding: 0; 
    flex-direction: column; 
  }
  .recognition-image{
    width: 10em; 
    height: 10em;
    margin-top: 1em; 
    margin-bottom: 1em;
  }
  .recognition-donate-button
  {
    margin-top: 1em; 
    margin-bottom: 1em;
  }
  .r-card {
    min-width: 300px;
    max-width: 300px;
    font-size: large;
  }
  .r-card img {
    width: 100px;
    height: 100px;
  }
  .recognition-content {
    overflow-y: scroll;
  }
  .r-carousel {
    min-height: 400px;
  }
}